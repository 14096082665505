.admin_modal_wrapper_template{
    display: flex;
    justify-content: center;
    flex-direction: column;
align-items: center;
}
.admin_modal_template{
    max-width: 700px;
}


  .admin_modal_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .admin_modal_title {
    color: #fff;
    text-align: center;
  
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  
  .admin_modal_closer {
    display: flex;
    width: 26px;
    height: 26px;
    padding: 6.5px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #3f456b;
    cursor: pointer;
    border: none;
    outline: none;
    margin-left: auto;
    transition: all 0.4s ease;
  }
  .admin_modal_closer:hover {
    transition: all 0.4s ease;
    background: #5d638e;
  }
  
  .admin_auth_togglers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 22px;
  }
  
  .admin_auth_toggle_button {
    display: flex;
    width: 48%;
    height: 42px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #555b89;
    cursor: pointer;
  
    color: #fff;
    transition: all 0.4s ease;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
  }
  .admin_auth_toggle_button:hover {
    transition: all 0.4s ease;
    background: #697cdd;
  }
  
  .admin_active_auth_type {
    background: #697cdd;
    border: none;
    transition: all 0.4s ease;
  }
  
  .admin_aouth_login_inputs {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .admin_aouth_login_input {
    font-family: "Rubik", sans-serif;
    display: flex;
    width: 100%;
    height: 42px;
    text-indent: 14px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: none;
    outline: none;
    border-radius: 6px;
    background: #10162c !important;
    margin-bottom: 6px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  
  .admin_auth_socials {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .admin_promocode_block div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    transition: all 0.4s ease;
  }
  .admin_promocode_block input {
    display: flex;
    width: 182px;
    height: 22px;
    border-radius: 2px;
    background: var(--unnamed, #10162c);
    border: none;
    outline: none;
    margin-bottom: 15px;
  
    color: #c1caf0;
    font-family: "Rubik", sans-serif;
    font-size: 13px;
    text-indent: 8px;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
  }
  
  .admin_promocode_block:hover p,
  .admin_promocode_block svg * {
    transition: all 0.4s ease;
    color: #c1caf0;
    fill: #c1caf0;
  }
  
  .admin_promocode_block p {
    color: #a0a9cf;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
    margin-right: 6px;
    transition: all 0.4s ease;
  }
  
  .admin_policy_block {
    display: flex;
    align-items: start;
    margin-bottom: 35px;
  }
  
  .admin_policy_block .admin_not_checked {
    height: 18px;
    padding-right: 18px;
    background: #fff;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 5px;
  }
  .admin_policy_block .admin_checked_policy {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .admin_policy_block p {
    color: rgba(160, 169, 207, 0.6);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 109.091% */
  }
  .admin_policy_block p a {
    color: rgba(160, 169, 207, 0.6);
    text-decoration: underline;
  }
  
  .admin_submit_btn {
    display: flex;
    width: 100%;
    height: 42px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #69dd89;
    border: none;
    outline: none;
    cursor: pointer;
  
    color: #191d3e;
    font-family: "Rubik", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 24px;
  }
  
  .admin_auth_types p {
    text-align: center;
    color: #a0a9cf;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
  }
  .admin_auth_types span {
    color: #69dd89;
    cursor: pointer;
  }
  
  /* Login modal styles */
  
  .admin_login_greeting {
    margin-top: 8px;
    color: #a0a9cf;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }
  
  .admin_login_modal .admin_aouth_login_inputs {
    margin-top: 20px;
    margin-bottom: 2px;
  }
  .admin_login_selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .admin_or_line {
    width: 100%;
    height: 1px;
    background: rgba(104, 117, 170, 0.6);
  }
  
  .admin_login_selection p {
    color: #6875aa;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    margin: 0 14px;
  }
  
  .admin_password_recovery_btn {
    margin-bottom: 35px;
    text-align: right;
    cursor: pointer;
    color: #a0a9cf;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
  }
  .admin_tg_login_button div #telegram-login-GGLegadropbot {
    font-size: 0 !important;
  }
  
