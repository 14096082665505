.app_content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app_content-wrapper {
  flex: 1;
}

.faq-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

/* Контейнер для категорий */
.faq-categories {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Для Firefox */
  -ms-overflow-style: none; /* Для IE и Edge */
}

.faq-categories::-webkit-scrollbar {
  display: none; /* Скрытие скроллбара */
}

.faq-category {
  cursor: pointer;
  width: 232px;
  padding: 26px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  background: #232b51;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.categoryName.active {
  color: #ff7f50;
}



/* Контейнер вопросов */
.faq-questions {
  border-top: 1px solid #444;
  margin-top: 20px;
}

.faq-item {
  border-bottom: 1px solid #444;
  padding: 10px 0;
  cursor: pointer;
}

.faq-question {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}

.faq-answer {
  margin-top: 10px;
  font-size: 14px;
  color: #bbb;
}
.faq{
  width: 100%; /* Занимает всю ширину экрана */
  max-width: 1400px; /* Ограничение на максимальную ширину */
  color: #fff;
  padding: 20px;
  border-radius: 10px;

}


/* Media queries для устройств с меньшей шириной */
@media (max-width: 980px) {
  .faq-category {
    font-size: 12px;
    padding: 8px 16px;
  }

  .faq-question {
    font-size: 14px;
  }

  .faq-answer {
    font-size: 12px;
  }
  .faq{
    max-width: 59.5rem !important;
  }
}
@media (max-width: 845px) {
  .faq{
    max-width: 37.5rem !important;
  }
}
@media (max-width: 845px) {
  .faq{
    max-width: 37.5rem !important;
  }
  @media (max-width: 600px) {
    .faq-container {
      display: flex;
      justify-content: center;
      padding: 20px;
    }
    
    .faq {

      color: white;
      padding: 20px;
      border-radius: 10px;
      max-width: 800px;
      width: 100%;
    }
    
    .faq-categories {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    

    

    
    .faq-questions {
      margin-top: 20px;
    }
    
    .faq-item {
      margin-bottom: 15px;
    }
    
    .faq-question {
      font-weight: bold;
    }
    
    .faq-answer {
      margin-top: 10px;
      color: #ccc;
    }
    
  }
}

