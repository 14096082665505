.deal-wheel{
  position: absolute;
}

.spinner {
  transform: rotate(calc(var(--rotate, 0) * 1deg));
}

.is-spinning .spinner {
  transition: transform 8s cubic-bezier(0.1, -0.01, 0, 1);
}
.spinner svg{
  width: 370px;
  height: 370px;
}
@media only screen and (max-width: 1014px) {
  .spinner svg{
    width: 300px;
    height: 300px;
  }
}
@media only screen and (max-width: 640px) {
  .spinner svg {
    width: 260px;
    height: 260px;
  }
}
@media only screen and (max-width: 560px) {
  .spinner svg {
    width: 240px;
    height: 240px;
  }
}
@media only screen and (max-width: 500px) {
  .spinner svg {
    width: 170px;
    height: 170px;
  }
}