.live_drop_item {
  padding: 5px;
  border-radius: 10px;
  border-radius: 10px;
  text-align: center;
  width: 100px;
  height: 94px;
  margin-right: 4px;
  cursor: pointer;
}
.live_drop_items_list {
  display: flex;
  align-items: center;
}

.item_1 {
  background: linear-gradient(180deg, #4255cf 0%, #2336b7 53.65%, #313970 100%);
}
.item_2 {
  background: linear-gradient(180deg, #b556ff 0%, #833dd9 53.65%, #4b3cb5 100%);
}
.item_3 {
  background: linear-gradient(
    180deg,
    rgba(255, 210, 95, 0.9) 0%,
    rgba(203, 168, 28, 0.72) 39.58%,
    rgba(187, 160, 55, 0.38) 100%
  );
}
.live_drop_items_list p {
  margin-top: -10px;
  white-space: nowrap;
  padding: 0 5px;
  overflow: hidden;
  color: #eaefff;
  text-align: center;
  text-overflow: ellipsis;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.live_drop_item_user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(20%);
  transition: all 0.5s ease;
  text-align: center;
}
.live_drop_item_user p {
  margin-top: 1px;
}
.live_drop_item_content {
  transition: all 0.5s ease;
  cursor: pointer;
}
.live_drop_item_user img {
  margin-right: -8px;
  margin-bottom: -10px;
  max-width: 80%; 
  max-height: 80%; 
  object-fit: contain;
  
}

.live_drop_item:hover .live_drop_item_user {
  transform: translateY(-100%);
  transition: all 0.5s ease;
}
.live_drop_item:hover .live_drop_item_content {
  transform: translateY(-150%);
  transition: all 0.5s ease;
}

.best_drop_content img {
  margin: 0;
}

.get_items_image{
  width: 68px;
  height: 68px;
}
.get_items_user_image{
  width: 78px;
  height: 68px;
}
@media only screen and (max-width: 520px) {
  .live_drop_item {
    text-align: center;
    width: 80px;
    height: 92px;
    margin-right: 4px;
    flex: 0 0 auto;
  }
}
