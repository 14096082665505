.back_button button {
  margin: 24px 0;
  width: 140px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #242c54;
  cursor: pointer;
  border: none;
  outline: none;

  color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.back_button button svg {
  margin-right: 10px;
}

.case_title {
  display: flex;
  align-items: center;
}
.case_title h1 {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 118.182% */
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  padding-right: 140px;
}

.about_item_bg {
  width: 100%;
  height: 258px;
  border-radius: 10px;
  background: #242c54;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.about_item_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about_item_content img {
  max-width: 250px;
}
.case_page_case_img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.unauthorized{
  display: flex;
  padding-right: 0px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.unauthorized_message {
  text-align: center;
  padding: 16px 40px;
  border-radius: 6.68px;
  background: rgba(71, 43, 71, 0.96);
  backdrop-filter: blur(1px);
  margin-bottom: 14px;

}
.unauthorized_message p {
  color: var(--Red, #ff6767);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
}

.unauthorized_message span {
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
}

.unauthorized_message_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.case_items_block h3 {
  margin-bottom: 40px;
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 118.182% */
  text-transform: uppercase;
  text-align: center;
}

.case_items_content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  margin-bottom: 100px;
}

.topup_page_btn {
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topup_page_btn svg {
  margin-left: 10px;
}

.unauthorized_message .case_page_cost {
  color: #69dd89;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
}

.open_case_block {
  display: flex;
  align-items: center;
  padding-right: 60px;
  gap: 18px;
}
.upgade_chances {
  display: flex;
  align-items: center;
}
.upgade_chances_items {
  display: flex;
  width: 50px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(53, 63, 113, 0.5);
  margin: 0 1px;
  cursor: pointer;
}
.upgade_chances_items:first-child {
  border-radius: 8px 0px 0px 8px;
}

.upgade_chances_items:last-child {
  border-radius: 8px 0px 0px 8px;
  border-radius: 0px 8px 8px 0px;
}

.upgade_chances_items p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}

.open_fast_btn {
  display: flex;
  padding: 16px 22px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: linear-gradient(
    86deg,
    #272e57 2.32%,
    rgba(233, 220, 255, 0.16) 166.98%
  );
  border: none;
  outline: none;
  cursor: pointer;
  color: rgba(224, 230, 255, 0.9);
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}

.open_case_btn {
  padding: 16px 22px;
}

.case_opening_items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1px;
}
.case_opening_multiple_items{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /*gap: 5px;*/
}

.case_opening_items_wrapper {
  width: 1200px;
  scrollbar-width: none;
  scroll-behavior: smooth;
  white-space: nowrap;
  overflow-x: auto;
  position: relative;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: clip;
}
.case_opening_items_wrapper::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.case_opening_item {
  width: 183px;
  height: 260px;
  border-radius: 8.3px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 60px;
}
.case_opening_process {
  margin-top: 30px;
  margin-bottom: 65px;
}
.case_opening_item_bg_firstly {
  background: linear-gradient(
      180deg,
      rgba(36, 70, 78, 0) 0%,
      rgba(82, 172, 192, 0.22) 90%
    ),
    #191d3e;
}
.case_opening_item_bg_secondary {
  background: linear-gradient(
      180deg,
      rgba(73, 78, 84, 0) 0%,
      rgba(130, 138, 150, 0.24) 90%
    ),
    #191d3e;
}

.case_opening_item_description p {
  color: #fff;
  text-align: center;
  font-family: Rubik;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  word-wrap: break-word;
  white-space: pre-line;
  padding: 0px 5px;
}

.case_opening_item_cost p {
  color: var(--Positive, #69dd89);
  font-family: Rubik;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 117.647% */
  margin-top: 2px;
}

.case_opening_marker {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -14px;
}

.case_opening_process_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.case_opening_process_btn button {
  padding: 16px 32px;
  border-radius: 8px;
  background: rgba(53, 63, 113, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.6);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}

.case_opening_left_gr,
.case_opening_right_gr {
  position: absolute;
}
.case_opening_left_gr {
  left: 0;
  top: 0;
}
.case_opening_right_gr {
  right: -1px;
  top: 0;
}
.case_opening_left_marker{
  position: absolute;
  left: 0;
}
.case_opening_right_marker{
  position: absolute;
  right: -1px;
  transform: rotate(180deg);
}
.case_opening_line{
  position: absolute;
  width: -webkit-fill-available;
  border-bottom: 1px solid #7F8BAD;
}
.case_opening_marker svg {
  position: relative;
}
.nav_auth_btns:disabled{
  cursor: default;
  background-color: #69dd899e;
}
.open_fast_btn:disabled{
  cursor: default;
  background: #4e5472;
}
.about_item_bg_wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  margin-bottom: 65px;
}
.case_opening_items_list{
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 1300px) {
  .case_opening_items_wrapper{
    width: 1000px;
  }
  .case_opening_items_list{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 1110px) {
  .case_opening_items_wrapper{
    width: 900px;
  }
}
@media only screen and (max-width: 1000px) {
  .case_items_content {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media only screen and (max-width: 950px) {
  .case_opening_items_wrapper{
    width: 800px;
  }
}
@media only screen and (max-width: 850px) {
  .case_opening_items_wrapper{
    width: 700px;
  }
}
@media only screen and (max-width: 820px) {
  .open_case_block {
    flex-direction: column;
    padding: 0;
    gap: 12px;
  }
  .unauthorized_message_wrapper {
    position: relative;
  }
  .about_item_bg_wrapper{
    margin-bottom: 50px;
  }
  .case_items_content {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 750px) {
  .case_opening_items_wrapper{
    width: 600px;
  }
  .case_opening_item_img img {
    width: 80px;
    height: 80px;
  }
  .case_opening_item{
    width: 120px;
    height: 150px;
    padding-top: 5px;
  }
}
@media only screen and (max-width: 650px) {
  .case_items_content {
    grid-template-columns: repeat(3, 1fr);
  }
  .winned_block_actions_btns{
    flex-direction: column;
    margin-left: 0px;
  }
  .case_opening_items_wrapper {
    width: 500px;
  }
}
@media only screen and (max-width: 550px) {
  .case_opening_items_wrapper {
    width: 400px;
  }
}
@media only screen and (max-width: 500px) {
  .case_items_content {
    grid-template-columns: repeat(2, 1fr);
  }
  .p_back{
    display: none;
  }
  .back_button button{
    width: 50px;
  }
  .back_button button svg{
    margin-right: 0px;
  }
  .case_title h1 {
    line-height: 22px;
    padding-right: 0px;
    font-size: 18px;
    margin: 0px;
  }
  .case_title {
    gap: 58px;
  }
  .about_item_bg {
    height: 224px;
  }
  .about_item_content img {
    max-width: 254px;
  }
  .case_page_case_img {
    width: 254px;
  }
  .about_item_bg_wrapper {
    gap: 24px;
  }
  .case_items_block h3{
    font-size: 18px;
    line-height: 22px;
    text-align: justify;
    margin-bottom: 18px;
  }
}