.snackbar_wrapper {
  position: fixed;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  border-radius: 8px;
  top: 65px;
  right: 45px;
  z-index: 999;
}

.snackbar_wrapper p {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.snackbar_wrapper_error{
  background: #FF6767;
}
.snackbar_wrapper_success{
  background: #69DD89;
}
.snackbar_error {
  margin-left: 20px;
  text-align: left;
}

.snackbar_error p {
  color: #fff;
}
