.cases_paginations {
  margin-top: 30px;
  margin-bottom: 40px;
  position: relative;
  padding-bottom: 40px;
}
.cases_paginations ul {
  display: flex;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
  row-gap: 10px;
}
.cases_paginations ul li {
  list-style: none;
}
.cases_paginations ul li a {
  display: flex;
  width: 50px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 1px;
  cursor: pointer;
  transition: all 0.4s ease;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.cases_paginations ul li:hover {
  transition: all 0.4s ease;
  background: rgba(53, 63, 112, 0.314) !important;
  color: #fff;
}

.cases_paginations ul li:nth-child(2) {
  border-radius: 8px 0px 0px 8px;
}
.cases_paginations ul li:nth-last-child(2) {
  border-radius: 0px 8px 8px 0px;
}

.cases_paginations ul li{
  background: rgba(53, 63, 113, 0.5);
}

.cases_paginations .selected {
  transition: all 0.4s ease;
  background: #353F71 !important;
}
.cases_paginations .selected:hover {
  transition: all 0.4s ease;
}
.cases_paginations .previous,
.cases_paginations .next {
  border-radius: 3px;
  position: absolute;
}
.cases_paginations .previous {
  bottom: -25px;
}
.cases_paginations .next {
  left: 190px;
  bottom: -25px;
  border-radius: 8px;
}
.cases_paginations .previous a,
.cases_paginations .next a {
  padding: 16px 32px;
  border-radius: 8px;
  background: #353F71;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  transition: all 0.4s ease;
  width: auto !important;
}

.cases_paginations .previous a:hover,
.cases_paginations .next a:hover {
  background: rgba(53, 63, 112, 0.314) !important;
  color: #fff;
}

.cases_paginations .disabled:hover {
  /*background: rgba(53, 63, 112, 0.314) !important;*/
  color: #fff;
  cursor: default;
}
.cases_paginations .disabled a:hover {
  color: #fff;
  cursor: default;
}
.cases_paginations .disabled {
  cursor: default;
  color: #fff;
  border-radius: 8px;
}
.cases_paginations .disabled a {
  cursor: default;
  color: #fff;
  background:  rgb(123 128 152) !important;
}
.cases_paginations .disabled a:hover {
  cursor: default;
  color: #fff;
  background: rgb(123 128 152) !important;
}

.cases_table_actions_list svg {
  transition: all 0.3s ease;
}
.cases_table_actions_list svg:hover {
  transition: all 0.3s ease;
  opacity: 0.7;
}
@media only screen and (max-width: 850px) {
  .cases_paginations .next{
    left: 0px;
    bottom: -90px;
  }
  .cases_paginations {
    margin-bottom: 105px;
  }
}