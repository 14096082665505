.profile_page {
  display: flex;
  align-items: start;
  justify-content: space-between;
  min-height: 90vh;
}

.profile_menu {
  padding: 22px;
  border-radius: 15px;
  background: #12152e;
  width: 40%;
  margin-bottom: 60px;
}
.profile_content {
  margin-bottom: 20px;
  width: 58.5%;
  border-radius: 15px;
  padding: 20px;
  background: linear-gradient(142deg, #20284b 4.07%, #242c54 90.15%);
}

.user_profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_descriptions {
  display: flex;
  align-items: start;
}
.user_avatar {
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 10px;
}
.user_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user_moneys {
  display: flex;
  align-items: center;
}
.user_descr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
}
.user_name {
  color: #b5c2e5;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
  margin-bottom: 6px;
}

.user_moneys p {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  margin-left: 6px;
}

.replenish_wallet {
  display: flex;
  width: 46px;
  height: 46px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #69dd89;
  cursor: pointer;
}

.user_id {
  margin: 18px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: #202446;
  padding: 12px 16px;
}

.user_id_input p {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 120% */
}

.user_id_input span {
  color: #7f8bad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}
.user_id_actions {
  display: flex;
}

.user_id_actions div {
  cursor: pointer;
  margin-left: 7px;
}
.bonus_activation p {
  text-align: center;
  margin: 18px 0;

  color: #7f8bad;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.profile_menu_list_item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.profile_menu_list_item p {
  color: rgba(255, 255, 255, 0.6);
  margin-left: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  transition: all 0.4s ease;
}
.profile_menu_list_item:hover p {
  color: #fff;
  transition: all 0.4s ease;
}
.profile_menu_list_item:hover {
  background: #202446;
  transition: all 0.4s ease;
}
.profile_menu_list_item:hover svg * {
  fill-opacity: 1;
}

.profile_menu_list .active .profile_menu_list_item {
  background: #202446;
  transition: all 0.4s ease;
}

.profile_menu_list .active .profile_menu_list_item p {
  color: #fff;
  transition: all 0.4s ease;
}
.profile_menu_list .active .profile_menu_list_item svg * {
  fill-opacity: 1;
  transition: all 0.4s ease;
}

.profile_menu_line {
  width: 100%;
  height: 1px;
  background: #313665;
  margin: 18px 0;
}


.uid_input{
  font-family: "Rubik", sans-serif;
  display: flex;
  width: 100%;
  height: 42px;
  text-indent: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: none;
  outline: none;
  border-radius: 6px;
  background: #202446 !important;
  color: var(--white-03, rgba(255, 255, 255, 0.3));
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.uid_input[type="text"][readonly] {
  cursor: default;
}

.uid_input.editing {
  border-bottom: 1px solid #007bff;
}
