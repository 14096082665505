@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #191d3e;
}

/* body:before {
  position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(https://forcedrop.gg/public/img/new-bg.jpg?v=2);
    background-size: 100% auto;
    opacity: .3;
    z-index: -2;
} */

a {
  text-decoration: none;
}

.modal_overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 111;
  background: #00000098;
}

a,
p,
div,
img,
h1,
span {
  -webkit-tap-highlight-color: transparent;
  color: #fff;
}
button {
  cursor: pointer;
  border: none;
}

.page_template {
  width: 1200px;
  margin: 0 auto;
}
.tgme_widget_login.large button.tgme_widget_login_button {
  font-size: 0px !important;
  line-height: 0px !important;
  width: 44px !important;
  height: 43px !important;
}

/* Отключение желтого фона в Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active input:-webkit-autofill:active {
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}

.main_btn_temp {
  padding: 16px 32px;
  border-radius: 8px;
  background: #69dd89;
  border: none;
  color: #191d3e;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  transition: all 0.4s ease;
}

.main_btn_green {
  transition: all 0.4s ease;
}
.main_btn_green:hover {
  background: #9ffcc5;
}
.grey_btn {
  transition: all 0.4s ease;
}
.grey_btn:hover {
  transition: all 0.4s ease;
  background: rgba(53, 63, 112, 0.314);
}
.grey_btn_active {
  transition: all 0.4s ease;
  background: #353F71 !important;
}
.grey_btn_active:hover {
  transition: all 0.4s ease;
  background: rgba(53, 63, 112, 0.314) !important;
}

.grey_icon {
  transition: all 0.4s ease;
  cursor: pointer;
}
.grey_icon:hover {
  transition: all 0.4s ease;
  opacity: 0.7;
}
.orange_btn {
  transition: all 0.4s ease;
}
.orange_btn:hover {
  transition: all 0.4s ease;
  background: #e29403;
}

.empty_message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  font-size: 18px;
  height: 330px;
  text-align: center;
}

.empty_message h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.empty_message a {
  color: #f89a3a;
  text-decoration: underline;
  cursor: pointer;
}

.empty_message a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 1220px) {
  .page_template {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}


