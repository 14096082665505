.agreement-page {
    width: 1200px;
    margin: 0 auto 50px;
}

.agreement-h1 {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}

.agreement-label {
    font-size: 19px;
    font-weight: 500;
    margin: 5px 0;
}