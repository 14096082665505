.upgrade_block {
  border-radius: 15px;
  background: #242c54;
  margin-bottom: 13px;
  padding: 60px 80px 60px;
}
.upgrade_wrapper {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
}
.updating_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.updating_item_img {
  width: 174px;
  height: 174px;
  margin-bottom: -20px;
}

.updating_bottom_img {
  width: 240px;
}

.upgrade_wrapper p {
  color: #7f8bad;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 22px;
  line-height: 18px; /* 128.571% */
}
.updating_chance {
  position: relative;
  height: 336px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updating_chance img {
  width: 336px;
  height: 336px;
  position: absolute;
  z-index: 1;
}

.updating_percent {
  margin-top: 0px;
  z-index: 1;
}
.updating_percent p {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px; /* 130% */
  text-transform: uppercase;
  margin-top: 0px;
}

.updating_percent span {
  color: #fdfdfd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  text-transform: uppercase;
  
}

.updating_item_to {
  margin-bottom: -18px;
}

.update_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upgrade_secondary_block {
  display: flex;
  align-items: start;
  margin-bottom: 50px;
  justify-content: space-between;
}
.upgrade_secondary_block_item {
  padding: 20px;
  border-radius: 15px;
  background: #12152e;
  width: 49%;
}
.upgrade_secondary_block_item_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.upgrade_secondary_block_item_title p {

  color: #e0e6ff;
  font-family: Rubik;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 105.882% */
}

.upgrade_secondary_items_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.upgrade_secondary_items_wrapper .case_item {
  padding: 8px 5px;
  width: 131px;
  height: 130px;
  margin-bottom: 7px;
}
.upgrade_secondary_items_wrapper .case_item img {
  width: 70px;
}

.upgrade_block_sort {
  display: flex;
  align-items: center;
  gap: 11px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}
.upgrade_block_sort p {
  margin: 0;
}
.upgrade_balance_input{
  width: 100%;
  height: 42px;
}
.user_id_input{
  display: flex;
  gap: 10px;
  align-items: center;
}
.updating_percent.updating_item_img p{
  margin-top: 65px;
}
.arrow_updating{
  position: absolute;
  width: 330px;
}
.circular-progressbar{
  position: absolute;
  width: 275px !important;
  z-index: 0;
}
.upgrade_percent_red{
  color: #FF6767 !important;
}
.upgrade_percent_green{
  color: #69DD89 !important;
}
.update_btn{
  margin-top: -10px;
}
.upgrade_page{
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.update_change_block{
  display: flex;
  gap: 18px;
}
.update_middle_block{
  display: flex;
  justify-content: space-between;
}
.default_change_btn,
.active_change_btn{
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.active_change_btn{
  border-bottom: 2px solid #69DD89;
}
.update_change_block button p {
  padding: 4px 6px;
  font-family: "Rubik", sans-serif;
  color: #7F8BAD;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.updating_percent_img img{
  top: 4px;
  left: -6px;
  width: 210px;
  height: 210px;
}
.updating_percent_img{
  position: absolute;
  width: 200px;
  height: 200px;
}
.update_success_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.update_h3_success{
  color: #69DD89;
  font-family: "Rubik", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}
.update_p_success{
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #FDFDFD;
}
.wrapper_update_btn_block{
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}
.wrapper_upgrade_price{
  font-family: "Rubik", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #69DD89;
  display: flex;
  flex-direction: column;
}
.wrapper_upgrade_price p {
  text-align: center;
}
.update_btn_wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}
.upgrade_balance_input {
  --c: #69DD89;
  --g: 2px;
  --l: 4px;
  --s: 13px;
  
  --_c: color-mix(in srgb, var(--c), #000 var(--p,0%));
  -webkit-appearance :none;
  -moz-appearance :none;
  appearance :none;
  background: none;
  cursor: pointer;
  overflow: hidden;
}
/* chromium */
.upgrade_balance_input[type="range" i]::-webkit-slider-thumb{
  height: var(--s);
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 0 0 0 var(--_b,var(--l)) inset var(--_c);
  border-image: linear-gradient(90deg,var(--_c) 50%,#10162C 0) 0 1/calc(50% - var(--l)/2) 100vw/0 calc(100vw + var(--g));
  -webkit-appearance: none;
  appearance: none;
  transition: .3s;
}
/* Firefox */
.upgrade_balance_input[type="range"]::-moz-range-thumb {
  height: var(--s);
  width: var(--s);
  background: none;
  border-radius: 50%;
  box-shadow: 0 0 0 var(--_b,var(--l)) inset var(--_c);
  border-image: linear-gradient(90deg,var(--_c) 50%,#ababab 0) 0 1/calc(50% - var(--l)/2) 100vw/0 calc(100vw + var(--g));
  -moz-appearance: none;
  appearance: none;
  transition: .3s;
}
@supports not (color: color-mix(in srgb,red,red)) {
  .upgrade_balance_input {
    --_c: var(--c);
  }
}
.upgrade_balance_input:focus {
    outline: none;
}
.h-20{
  height: 20px;
}
.btn_upgrade_balance{
  padding: 4px 6px;
  border-radius: 8px;
  background: #69dd89;
  border: none;
  color: #191d3e;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.upgrade_balance_input_text{
  font-family: "Rubik", sans-serif;
  display: flex;
  width: 100%;
  height: 35px;
  text-indent: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: none;
  outline: none;
  border-radius: 6px;
  background: #10162c !important;
  margin-bottom: 18px;

  color: var(--white-03, rgba(255, 255, 255, 0.3));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media only screen and (max-width: 1220px) {
  .upgrade_secondary_items_wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .upgrade_case-item-wrapper{
    display: flex;
    justify-content: center;
  }
  .updating_bottom_img {
    width: 220px;
  }
  .circular-progressbar {
    width: 257px !important;
  }
  .updating_item_img {
    width: 160px;
    height: 160px;
  }
  .updating_chance {
    height: 280px;
  }
  .updating_chance img {
    width: 320px;
    height: 320px;
  }
  .upgrade_wrapper p{
    width: 260px;
  }
  .updating_percent p {
    font-size: 36px;
    line-height: 47px;
  }
  .updating_percent.updating_item_img p{
    display: flex;
    justify-content: center;
    width: 155px;
  }
  .updating_percent_img img{
    top: 13px;
    left: 5px;
    width: 190px;
    height: 190px;
  }
}
@media only screen and (max-width: 1014px) {
  .updating_bottom_img {
    width: 200px;
  }
  .circular-progressbar {
    width: 224px !important;
  }
  .upgrade_wrapper p{
    width: 220px;
    font-size: 12px;
  }
  .updating_percent p {
    font-size: 32px;
    line-height: 36px;
  }
  .updating_item_img {
    width: 140px;
    height: 140px;
  }
  .updating_chance img {
    width: 280px;
    height: 280px;
  }
  .updating_percent_img img{
    top: 27px;
    left: 20px;
    width: 160px;
    height: 160px;
  }
  .update_h3_success{
    font-size: 30px;
    line-height: 30px;
  }
  .update_p_success{
    font-size: 13px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 980px) {
  .update_btn{
    margin-top: 14px;
  }
  .upgrade_wrapper{
    position: relative;
  }
  .upgrade_block {
    padding: 50px 80px 50px;
  }
  .updating_percent span {
    font-size: 13px;
  }
  .updating_percent p {
    font-size: 28px;
    line-height: 27px;
  }
  .updating_chance {
    position: absolute;
    left: 50%;
    right: 50%;
  }
  .upgrade_item_left,
  .upgrade_item_right{
    margin-top: 190px;
  }
  .upgrade_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .upgrade_item_left{
    align-items: flex-start;
  }
  .upgrade_item_right{
    align-items: flex-end;
  }
  .updating_chance{
    top: 0px;
  }
  .upgrade_item_right .updating_item_img{
    margin-right: 30px;
  }
  .upgrade_item_left .updating_item_img{
    margin-left: 30px;
  }
  .upgrade_secondary_items_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 780px) {
  .upgrade_item_right {
    margin-bottom: 20px;
  } 
  .upgrade_item_left, .upgrade_item_right {
    margin-top: 210px;
  }
  .upgrade_item_left{
    margin-bottom: 20px;
  }
  .upgrade_item_right p {
    text-align: right;
  }
  .upgrade_item_left p {
    text-align: left;
  }
}
@media only screen and (max-width: 680px) {
  .upgrade_block {
    padding: 30px 30px 30px;
  }
  .updating_bottom_img {
    width: 170px;
  }
  .updating_item_img {
    width: 120px;
    height: 120px;
  }
  .upgrade_item_right .updating_item_img {
    margin-right: 26px;
  }
  .upgrade_item_left .updating_item_img {
    margin-left: 26px;
  }
  .upgrade_secondary_block{
    flex-direction: column;
    gap: 20px;
  }
  .upgrade_secondary_block_item{
    width: 100%;
  }
  .upgrade_secondary_items_wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
  .updating_percent.updating_item_img p{
    display: flex;
    justify-content: center;
    width: 130px;
  }
  .upgrade_svg{
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 640px) {
  .upgrade_secondary_items_wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .updating_percent p {
    font-size: 24px;
    line-height: 23px;
  }
  .updating_chance img {
    width: 240px;
    height: 240px;
  }
  .circular-progressbar {
    width: 194px !important;
  }
  .updating_bottom_img {
    width: 150px;
  }
  .updating_item_img {
    width: 110px;
    height: 110px;
    margin-bottom: -8px;
  }
  .upgrade_item_right .updating_item_img {
    margin-right: 22px;
  }
  .upgrade_item_left .updating_item_img {
    margin-left: 22px;
  }
  .updating_percent_img img{
    top: 34px;
    left: 28px;
    width: 144px;
    height: 144px;
  }
  .update_h3_success{
    font-size: 20px;
    line-height: 20px;
  }
  .update_p_success{
    font-size: 10px;
    line-height: 14px;
  }
}
@media only screen and (max-width: 560px) {
  .upgrade_item_right p,
  .upgrade_item_left p {
    width: 170px;
    font-size: 10px;
  }
  .updating_chance img {
    width: 220px;
    height: 220px;
  }
  .circular-progressbar {
    width: 178px !important;
  }
  .updating_item_balance{
    font-size: 17px;
    width: 100px !important;
  }
  .upgrade_svg{
    width: 40px;
    height: 40px;
  }
  .updating_percent_img img{
    top: 41px;
    left: 35px;
    width: 130px;
    height: 130px;
  }
}
@media only screen and (max-width: 500px) {
  .updating_chance img {
    width: 160px;
    height: 160px;
  }
  .circular-progressbar {
    width: 130px !important;
  }
  .updating_chance {
    height: 155px;
  }
  .updating_percent p {
    font-size: 18px;
    line-height: 16px;
  }
  .updating_percent span {
    font-size: 9px;
  }
  .upgrade_item_left, .upgrade_item_right {
    margin-top: 150px;
    margin-bottom: 0px;
  }
  .updating_bottom_img {
    width: 132px;
  }
  .upgrade_item_right p, .upgrade_item_left p {
    display: none;
  }
  .updating_item_img {
    width: 100px;
    height: 100px;
    margin-bottom: -7px;
  }
  .upgrade_item_right .updating_item_img {
    margin-right: 17px;
  }
  .upgrade_item_left .updating_item_img {
    margin-left: 17px;
  }
  .upgrade_secondary_items_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .update_btn{
    margin-top: 30px;
  }
  .upgrade_svg{
    width: 35px;
    height: 35px;
  }
  .update_middle_block{
    display: flex;
    justify-content: space-between;
  }
  .updating_percent_img img{
    top: 58px;
    left: 54px;
    width: 92px;
    height: 92px;
  }
}
@media only screen and (max-width: 414px) {
  .updating_chance {
    height: 140px;
  }
  .updating_chance img {
    width: 135px;
    height: 135px;
  }
  .spinner svg {
    width: 150px;
    height: 150px;
  }
  .circular-progressbar {
    width: 95px !important;
  }
  .upgrade_block {
    padding: 30px 20px 50px;
    margin-bottom: 10px;
  }
  .updating_bottom_img {
    width: 114px;
  }
  .updating_item_img {
    width: 90px;
    height: 90px;
    margin-bottom: 0px;
  }
  .upgrade_item_left, .upgrade_item_right {
    margin-top: 165px;
  }
  .upgrade_item_right .updating_item_img {
    margin-right: 13px;
  }
  .upgrade_item_left .updating_item_img {
    margin-left: 13px;
  }
  .updating_percent_img img{
    top: 64px;
    left: 60px;
    width: 81px;
    height: 80px;
  }
}

.unauthorized_message {
  text-align: center;
  padding: 16px 40px;
  border-radius: 6.68px;
  background: rgba(71, 43, 71, 0.96);
  backdrop-filter: blur(1px);
  margin-bottom: 14px;
}
.unauthorized_message p {
  color: var(--Red, #ff6767);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
}

.unauthorized_message span {
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
}

.unauthorized_message_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

}
.unauthorized{
  display: flex;
  padding-right: 0px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}