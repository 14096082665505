.case_item {
  padding: 0px 16px;
  min-height: 158px;
  border-radius: 8.3px;
  background: #242c54;
  max-width: 183px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  gap: 4px;
}
.case_item p {
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
}
.case_item span {
  color: var(--Positive, #69dd89);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 117.647% */
  margin-bottom: 10px;
  margin-top: 5px;
}
.case_item_img{
  width: 94px;
  height: 94px;
}
.case_item_active {
  border: 2px solid #6977B9;
  background: #344079;
}
@media only screen and (max-width: 500px) {
  .case_item {
    max-width: 100%;
  }
}
