
.preloader {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    margin-top: 15px;
    font-size: 18px;
    color: #ffffff;
  }
  
  .sp {
    width: 60px;
    height: 60px;
    border: 8px solid #ffffff;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
