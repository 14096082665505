.ref_output_list_item_wrapper{
  padding: 12px 16px;
  border-radius: 10px;
  background: #191d3e;
}
.ref_output_list_item{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ref_output_right_block{
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.ref_output_left_block{
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.ref_output_list_item_show_more_wrapper {
  height: 0px;
  transition: height 1s;
}

.ref_output_list_item_show_more_wrapper.show {
  height: 125px;
}
.show_more_ref_output_block{
  margin-top: 20px;
}
.show_more_ref_output_block_p{
  display: flex;
  flex-direction: column;
  gap: 5px;
}