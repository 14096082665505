.select_pagination{
  width: 120px !important;
  background: rgba(53, 63, 113, 0.5);
}
.menu {
  animation: fade--in 0.3s ease-in-out;
}
.conditions_select{
  display: contents;
}

@keyframes fade--in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}