.profile_bests {
  display: flex;
  align-self: start;
  gap: 9px;
}

.profile_best_item {
  width: 49%;
  height: 150px;
  padding: 12px 22px;
  border-radius: 10px;
  background: #191d3e;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile_best_img {
  width: 145px;
}
.profile_best_img img {
  width: 100%;
}

.profile_best_item_description h4 {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 120% */
  text-transform: uppercase;
  margin-bottom: 3px;
}

.profile_best_item_description p {
  color: #7f8bad;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 100% */
  margin-bottom: 22px;
}

.open_case_profile {
  cursor: pointer;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fbae21;
  border: none;

  color: #191d3e;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}

.case_price_profile {
  display: flex;
  width: 62px;
  height: 26px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background: #233553;
  cursor: pointer;
}

.case_price_profile p {
  color: var(--Positive, #69dd89);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 117.647% */
}

.profile_items_list_item {
  padding: 12px 16px;
  border-radius: 10px;
  background: #191d3e;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.profile_items_list_item img {
  width: 50px;
  margin-right: 12px;
}
.profile_items_name {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  margin-bottom: 6px;
}

.profile_items_list_item_desc {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.profile_item_action {
  height: 24px;
  padding: 0 8px;
  border-radius: 8px;

  border-radius: 8px;
  background: #233553;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.profile_item_action p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.profile_item_actions_list {
  display: flex;
  gap: 8px;
}

.green_item {
  background: #233553;
}
.green_item p {
  color: var(--Positive, #69dd89);
}

.purple_item {
  background: #472c69;
}

.purple_item p {
  color: #e1a3ff;
}
.least_item {
  background: #22294e;
  color: #e1a3ff;
}
.profile_item_action svg {
  margin-right: 4px;
}
.btn_sale_all {
  margin: 15px 0px;
  display: inline-block; 
  padding: 0; 
}

.btn_sale_all button {
  padding: 16px 32px;
  border-radius: 8px;
  background: #191d3e;
  border: none;
  color: #fff;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  transition: all 0.4s ease;
  width: auto !important;
  cursor: pointer; 
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #191d3e;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 600px;
  width: 100%;
}

.modal-content p {
  font-size: 20px;
  margin: 20px 0;
}

.modal-content h3 {
  margin-bottom: 40px;
}

.modal-content button {
  margin: 10px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.modal-content button:first-of-type {
  background: #28a745;
  color: white;
}

.modal-content button:last-of-type {
  background: #dc3545;
  color: white;
}



@media only screen and (max-width: 920px) {
  .profile_best_item {
    flex-direction: column;
    height: auto;
    justify-content: start;
  }
  .profile_best_img {
    width: 100%;
  }
  .profile_best_item_description p {
    margin-bottom: 8px;
  }
  .profile_best_item_description {
    text-align: center;
  }
  .profile_item_actions_list {
    flex-wrap: wrap;
    gap: 10px;
  }
  .profile_best_item_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 920px) {
  .profile_content,
  .profile_menu {
    padding: 16px;
  }
  .empty_message {
    height: 200px;
  }
}

@media only screen and (max-width: 680px) {
  .profile_page {
    flex-direction: column;
  }
  .profile_menu,
  .profile_content {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .profile_content {
    margin-bottom: 32px;
  }
  .profile_best_item_description p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }
  .profile_best_item:nth-child(2) .profile_best_item_description h4 {
  color: #af9140;
  }
}

@media only screen and (max-width: 480px) {
  .profile_item_action p {
    font-size: 12px;
    line-height: 20px;
  }
  .profile_items_list_item {
    padding: 12px 8px;
  }
  .profile_best_item {
    padding: 16px;
  }
  .profile_best_item_description p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    max-width: 131px;
  }
  .upgrade_list_item_des div, .history_item_descriptions  {
    flex-direction: column;
    text-align: left;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
  .history_price {
    text-align: left !important;
  }
  .open_case_profile, .case_price_profile p {
    font-size: 14px;
  }
}
