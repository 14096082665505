.upgrade_list_item {
  border-radius: 10px;
  background: #191d3e;
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.upgrade_list_item_img {
  width: 48px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.upgrade_list_item_img img {
  width: 100%;
}
.upgrade_list_item_svg{
  width: 48px;
  height: 25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.defeat_icon {
  position: absolute;
  top: 6px;
  left: 3px;
}
.upgrade_list_item_des {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.upgrade_list_item_des div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history_status svg {
  margin-left: 4px;
}
.upgrade_balance_p{
  width: max-content;
  margin-right: 10px;
}
