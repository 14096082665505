.top_nav {
  width: 100%;
  background: #10162c;
}

.top_nav_wrapper {
  display: flex;
  width: 100%;
  padding: 13px 16px;
  justify-content: space-between;
  align-items: center;
}

.top_nav_left_list {
  display: flex;
  align-items: center;
}
.top_nav_left_item {
  margin-right: 24px;
}
.top_nav_left_item p {
  color: #7f8bad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  transition: all 0.4s ease;
}
.top_nav_left_item p:hover {
  transition: all 0.4s ease;
  color: #f7a34d;
}
.active .top_nav_left_item p {
  transition: all 0.4s ease;
  color: #f7a34d;
  fill: #f7a34d;
}
.top_nav_right_list {
  display: flex;
  align-items: center;
}
.top_nav_right_list div:last-child {
  margin-left: 14px;
}

.top_nav .main_logo,
.top_nav .nav_profile {
  display: none;
}
.mob_menu_wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 88px;
  right: 0;
  left: 0;
  background: #191d3e;
  z-index: 1111;
  transform: translateX(100%);
  transition: all 0.6s ease;
}
.visible_mob_menu_wrapper {
  transform: translateX(0);
  transition: all 0.6s ease;
}
.mob_menu_list {
  padding: 60px 16px 0;
}
.mob_menu_list .main_nav_menu_item {
  padding: 18px 0;
  border-bottom: 1px solid #2b3152;
}
.mob_menu_list .main_nav_menu_item p {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.main_logo_mobile {
  display: none;
}

@media only screen and (max-width: 820px) {
  .top_nav .top_nav_left_list,
  .top_nav .top_nav_right_list {
    display: none;
  }
  .top_nav .main_logo,
  .top_nav .nav_profile {
    display: flex;
  }
  .mob_menu_icon, .mob_menu_icon_exit {
    cursor: pointer;
    margin-left: 8px;
  }
  .top_nav {
    background: #191d3e;
  }
}

@media only screen and (max-width: 520px) {
  .main_logo_desctop {
    display: none;
  }
  .main_logo_mobile {
    display: flex;
  }
  .best_drop,
  .left_live_buttons {
    display: none;
  }
  .top_nav_wrapper {
    padding: 8px 16px;
  }
  .mob_menu_wrapper {
    top: 65px;
  }
}
