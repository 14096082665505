.user_page_profile {
  width: 50%;
  height: 164px;
  border-radius: 15px;
  background: #10162c;
  padding: 19px;
  display: flex;
  align-items: center;
}

.user_page_image_itemsdron {
  display: flex; /* Для выравнивания элементов по горизонтали */
  align-items: center; /* Выравнивание элементов по вертикали */
  justify-content: center; /* Центрирование элементов */
  gap: 15px; /* Расстояние между изображениями и иконкой */
  position: relative; /* Для позиционирования иконки относительно изображений */
}

.user_page_image_itemsdron img {
  max-width: 60%; /* Ограничиваем размер изображений */
  height: auto; /* Сохраняем пропорции изображений */
  border-radius: 8px; /* Добавляем скругленные углы для изображений */
  
}

.user_page_image_itemsdrones {
  display: flex; /* Для выравнивания элементов по горизонтали */
  align-items: center; /* Выравнивание элементов по вертикали */
  justify-content: center; /* Центрирование элементов */
  gap: 15px; /* Расстояние между изображениями и иконкой */
  position: relative; /* Для позиционирования иконки относительно изображений */
  width: 95px;
  height: 94px;
}
.user_page_image_itemsdrones img {
  max-width: 100%; /* Ограничиваем размер изображения */
  height: auto; /* Сохраняем пропорции изображения */
  border-radius: 8px; /* Добавляем скругленные углы для изображения */
}



.defeat_icon {
  position: absolute; /* Иконка будет поверх изображений */
  top: 50%; /* Центрируем по вертикали */
  left: 50%; /* Центрируем по горизонтали */
  transform: translate(-50%, -50%); /* Идеальное выравнивание */
  width: 40px; /* Размер иконки */
  height: 40px; /* Размер иконки */
  z-index: 10; /* Иконка будет поверх изображений */
}
.defeat_icondrones {
  position: absolute; /* Позиционируем иконку поверх изображения */
  top: 50%; /* Центрируем по вертикали */
  left: 50%; /* Центрируем по горизонтали */
  transform: translate(-50%, -50%); /* Выравнивание по центру */
  width: 50px; /* Установите нужный размер иконки */
  height: 50px; /* Установите нужный размер иконки */
  z-index: 10; /* Убедитесь, что иконка будет отображаться поверх изображения */
}
.user_page_profile img {
  width: 126px;
  height: 126px;
  border-radius: 15px;
  margin-right: 36px;
}

.user_page_profile p {
  color: #e0e6ff;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 111.111% */
  margin-bottom: 12px;
}
.user_page_profile button {
  border-radius: 20px;
  background: #69dd89;
  padding: 8px 14px;
  color: #10162c;
  font-family: Rubik;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 127.273% */
}

.user_page_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.user_page_top_drops {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user_page_top_drops .profile_best_item {
  border-radius: 10px;
  background: #232b51;
  padding: 24px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 285px;
  height: 165px;
}

.user_page_top_drops button {
  padding: 12px 20px;
}
.user_page_top_drops .case_price_profile {
  width: 95px;
  height: 45px;
}
.user_page_top_drops .profile_best_img {
  width: auto;
}
.user_page_top_drops .profile_best_img img {
  width: 97px;
}
.user_page_top_drops .profile_best_item_description p {
  margin-bottom: 32px;
}

.user_page_middle_btns {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}
.user_page_middle_btns button {
  width: 232px;
  padding: 26px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  background: #232b51;

  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.user_page_middle_btns button:first-child p {
  color: #f7a34d;
}
.user_page_middle_btns button:last-child p {
  color: rgba(255, 255, 255, 0.8);
}
.user_page_middle_btns button div {
  padding: 2px 8px;
  border-radius: 6px;
  background: #474f72;
}
.user_page_middle_btns button div span {
  color: #fff;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.user_page_crystal {
  width: 183px;
  height: 166px;
  padding: 12px 16px;
  border-radius: 8.3px;
  background: #242c54;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.user_page_crystal_top {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.user_page_crystal_top_state p {
  color: #fff;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  line-height: 20px; /* 142.857% */
  border-radius: 4px;
  background: #474f72;
  padding: 2px 8px;
}

.user_page_crystal_top_cost p {
  color: var(--Positive, #69dd89);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.user_page_crystal_descr p {
  color: #fff;
  text-align: center;
  font-family: Rubik;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
}

.user_page_crystals {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}

.user_page_crystals.upgrades {
  grid-template-columns: repeat(3, 1fr);
}

.user_show_more_btn {
  margin-top: 20px;
  margin-bottom: 80px;
}
.user_show_more_btn button {
  padding: 15px 32px;
  width: auto;
}
.user_page_image_items{
  width: 95px;
  height: 94px;
}

/* Апгрейды */

.user_page-upgrade {
  display: flex;
  flex-direction: column;
  background-color: rgba(36, 44, 84, 1);
  border-radius: 10px;
  padding: 20px;
  max-width: 387px;
}

.user_page-upgrade-items {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.user_page-upgrade-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 172px;
  width: 100%;
}

.user_page-upgrade-item:nth-child(2) .user_page-upgrade-name {
  color: #fff;
}

.user_page-upgrade-item-label {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: rgba(214, 215, 220, 1);
}

.user_page-upgrade-item-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(0deg, #43392D 0%, rgba(91, 73, 55, 0.43) 100%);
  border-radius: 8px;
  text-align: center;
  padding: 10px;

}

.user_page-upgrade-price {
  font-size: 13px;
  font-weight: 500;
  color: #5fff99;
  margin-left: auto;
}

.user_page-upgrade-icon {
  margin-bottom: 5px;
}

.user_page-upgrade-icon-src {
  width: 56px;
  height: 56px;
}

.user_page-upgrade-name {
  font-size: 14px;
  color: #ffb74d;
  font-weight: 500;
  line-height: 16px;
  margin-top: 5px;
}

.user_page-upgrade-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 20px;
  background: rgba(32, 36, 70, 1);
  border-radius: 10px;

}

.user_page-upgrade-chance {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user_page-upgrade-chance_percent {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

.user_page-upgrade-chance_name {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #7F8BAD;
}

.user_page-upgrade-status {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  padding: 8px 20px;
  border-radius: 4px;
}

.user_page-upgrade-status.status-success {
  background-color: #20585B;
  color:#69DD89;
}

.user_page-upgrade-status.status-failure {
  color: #FF6767;
  background: #DF49304D;
}

@media only screen and (max-width: 1230px) {
  .user_page_crystals {
    grid-template-columns: repeat(5, 1fr);
  }
  .user_page_profile {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 1014px) {
  .user_page_top_drops .profile_best_item {
    padding: 10px;
  }
  .user_page_crystals {
    grid-template-columns: repeat(4, 1fr);
  }
  .user_page_profile {
    margin: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 830px) {
  .user_page_crystals {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 610px) {
  .user_page_crystals {
    grid-template-columns: repeat(2, 1fr);
  }
  .user_page_middle_btns button {
    width: auto;
    padding: 12px 12px;
  }
}
@media only screen and (max-width: 420px) {
  .user_page_middle_btns svg, .user_page_middle_btns div {
  display: none;
  }
  .user_page_crystals {
  gap: 10px;
  }
  .user_page_crystal {
    width: 100%;
  }
  .user_page_profile img {
    width: 64px;
    height: 64px;
  }
}