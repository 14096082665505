.settings_user_img {
  width: 76px;
  height: 76px;
  border-radius: 10px;
  border: 2px solid #233452;
  overflow: hidden;
  margin-right: 14px;
  position: relative;
}
.set_user_image {
  position: absolute;
  z-index: 11;
  top: 50%;
  margin-top: -16px;
  left: 50%;
  margin-left: -16px;
  cursor: pointer;
  display: none;
}
.settings_user_img:hover .set_user_image {
  display: block;
}
.settings_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user_data_settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userid_setting {
  display: flex;
  align-items: center;
}

.username_setting p {
  color: #b5c2e5;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
}

.userid_setting p {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}
.userid_setting svg {
  width: 14px;
  cursor: pointer;
  margin-left: 6px;
}

.userid_setting svg * {
  fill: #fbae21;
}

.right_arrow {
  cursor: pointer;
}

.user_data_setting_content {
  display: flex;
  align-items: center;
}
.updating_userdata_form {
  margin-top: 18px;
}

.updating_form_item {
  margin-bottom: 12px;
}
.updating_form_item p {
  color: #7f8bad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-bottom: 4px;
}

.update_inpute {
  padding: 16px;
  border-radius: 8px;
  background: #191d3e;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.update_inpute input {
  width: 100%;
  height: 20px;
  outline: none;
  background: transparent;

  color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  border: 1px solid transparent;
}

.update_inpute svg:first-child {
  margin-right: 12px;
}
.update_inpute svg:last-child {
  cursor: pointer;
}
.logined_social {
  display: flex;
}
.logined_social div {
  cursor: pointer;
  margin-right: 8px;
  opacity: 0.2;
}
.google_icon {
  border-radius: 8px;
  background: #fff;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.google_icon svg {
  width: 25px;
}

.logined_socials_wrapper p {
  color: #7f8bad;
  margin-bottom: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

#user_avatar_update {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.update_inpute p {
  width: 100%;
  margin: 0;
  color: rgba(255, 255, 255, 0.4);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 150% */
}

.active_logined {
  opacity: 1 !important;
}

.default_user_password {
  margin-bottom: -12px !important;
  font-size: 25px !important;
  letter-spacing: 3px !important;
}

.error_password .update_inpute {
  border: 1px solid red !important;
}
