.modal_wrapper_template {
  position: fixed;
  z-index: 112;
  width: 100%;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_template {
  width: 391px;
  border-radius: 20px;
  background: #212648;
  position: fixed;
  z-index: 112;
  padding: 24px 20px;
  transition: all 0.4s ease;
}

.modal_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal_title {
  color: #fff;
  text-align: center;

  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.modal_closer {
  display: flex;
  width: 26px;
  height: 26px;
  padding: 6.5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #3f456b;
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: auto;
  transition: all 0.4s ease;
}
.modal_closer:hover {
  transition: all 0.4s ease;
  background: #5d638e;
}

.auth_togglers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
}

.auth_toggle_button {
  display: flex;
  width: 48%;
  height: 42px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid #555b89;
  cursor: pointer;

  color: #fff;
  transition: all 0.4s ease;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
}
.auth_toggle_button:hover {
  transition: all 0.4s ease;
  background: #697cdd;
}

.active_auth_type {
  background: #697cdd;
  border: none;
  transition: all 0.4s ease;
}

.aouth_login_inputs {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.aouth_login_input {
  font-family: "Rubik", sans-serif;
  display: flex;
  width: 100%;
  height: 42px;
  text-indent: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: none;
  outline: none;
  border-radius: 6px;
  background: #10162c !important;
  margin-bottom: 6px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.auth_socials {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.promocode_block div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.promocode_block input {
  display: flex;
  width: 182px;
  height: 22px;
  border-radius: 2px;
  background: var(--unnamed, #10162c);
  border: none;
  outline: none;
  margin-bottom: 15px;

  color: #c1caf0;
  font-family: "Rubik", sans-serif;
  font-size: 13px;
  text-indent: 8px;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
}

.promocode_block:hover p,
.promocode_block svg * {
  transition: all 0.4s ease;
  color: #c1caf0;
  fill: #c1caf0;
}

.promocode_block p {
  color: #a0a9cf;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
  margin-right: 6px;
  transition: all 0.4s ease;
}

.policy_block {
  display: flex;
  align-items: start;
  margin-bottom: 35px;
}

.policy_block .not_checked {
  height: 18px;
  padding-right: 18px;
  background: #fff;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
}
.policy_block .checked_policy {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 10px;
}

.policy_block p {
  color: rgba(160, 169, 207, 0.6);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 109.091% */
}
.policy_block p a {
  color: rgba(160, 169, 207, 0.6);
  text-decoration: underline;
}

.submit_btn {
  display: flex;
  width: 100%;
  height: 42px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #69dd89;
  border: none;
  outline: none;
  cursor: pointer;

  color: #191d3e;
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 24px;
}

.auth_types p {
  text-align: center;
  color: #a0a9cf;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
}
.auth_types span {
  color: #69dd89;
  cursor: pointer;
}

/* Login modal styles */

.login_greeting {
  margin-top: 8px;
  color: #a0a9cf;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.login_modal .aouth_login_inputs {
  margin-top: 20px;
  margin-bottom: 2px;
}
.login_selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.or_line {
  width: 100%;
  height: 1px;
  background: rgba(104, 117, 170, 0.6);
}

.login_selection p {
  color: #6875aa;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin: 0 14px;
}

.password_recovery_btn {
  margin-bottom: 35px;
  text-align: right;
  cursor: pointer;
  color: #a0a9cf;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
}
.tg_login_button div #telegram-login-GGLegadropbot {
  font-size: 0 !important;
}

.tlogin-button {
  width: 44px;
  overflow: hidden;
  height: 44px;
  border-radius: 5px;
  font-size: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tg_login_btn {
  width: 100%;
  height: 43px;
  background: #23a0dc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 15px;
  transition: .3s;
}

.tg_login_btn:hover {
  background: #23a0dcbd;
}

#vk-one-tap {
  width: 100%;
}
.google_auth_btn {
  width: 38px;
  height: 36px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}
.google_auth_btn_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 43px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

#telegram-login-GGLegadropbot {
  width: 52px !important;
  height: 43px !important;
  background: #54a9eb !important;
  border-radius: 8px !important;
  padding-top: 1px !important;
  padding-left: 0px !important;
}
.tgme_widget_login.large button.tgme_widget_login_button {
  font-size: 0px !important;
  line-height: 20px !important;
  border-radius: 0px !important;
}

button.tgme_widget_login_button {
  padding-left: 18px !important;
}
.tgme_widget_login.large
  button.tgme_widget_login_button
  .tgme_widget_login_button_icon {
  width: 24px;
  height: 24px;
  margin: 0 136px -2px -10px !important;
}

.mail_ru_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

#vk-floating-login-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.google_fake {
  width: 18px;
  margin-left: 1px;
  position: absolute;
  z-index: 111;
}
.display_none {
  display: none;
}

.auth_password_input {
  position: relative;
}
.hide_password {
  position: absolute;
  top: 10px;
  right: 18px;
  cursor: pointer;
}
.hide_password * {
  opacity: 0.5 !important;
}
.active_auth_password * {
  opacity: 1 !important;
}
.auth_password_input input {
  padding-right: 55px;
}

.error_auth_password input {
  border: 1px solid red;
  color: red;
}
#telegram-widget-container{
  height: 44px;
  width: 43px;
  overflow: hidden;
  position: relative;
  top: 3px;
  left: -4px;
}
@media only screen and (max-width: 420px) {
  .modal_template {
    width: 90%;
  }
}