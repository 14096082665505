.modal_template {
  width: 391px;
  border-radius: 20px;
  background: #212648;
  position: fixed;
  z-index: 112;
  padding: 24px 20px;
  transition: all 0.4s ease;
}
.modal_header_shop {
  width: 100%;
  display: flex;
  align-items: center;
}

.modal_header_shop-name {
  font-size: 16px;
  font-weight: 500;
}

.modal_select_withdraw{
  margin-top: 10px;
}

.modal_btns_shop {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.modal_btns_shop button {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  font-family: "Rubik", sans-serif;
}

.green_btns {
  background-color: #28a745;
}

.green_btns:hover {
  background-color: #218838;
}

.orange_btns_shop {
  background-color: #dc3545;
  margin-left: 10px;
}

.orange_btns_shop:hover {
  background-color: #c82333;
}
