nav {
  width: 100%;
}
.main_nav_bar {
  width: 1200px;
  margin: 0 auto;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main_nav_menu {
  display: flex;
  align-items: center;
}

.main_nav_menu_item {
  display: flex;
  align-items: center;
  margin: 0 7.5px;
  transition: all 0.4s ease;
}
.main_nav_menu_item svg * {
  transition: all 0.4s ease;
}
.main_nav_menu_item:hover p,
.main_nav_menu_item:hover svg * {
  transition: all 0.4s ease;
  color: #f7a34d;
  fill: #f7a34d;
}
.main_nav_menu_item p {
  margin-left: 6px;
  color: #e0e6ff;
  transition: all 0.4s ease;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 105.882% */
}
.main_nav_menu .active .main_nav_menu_item p,
.main_nav_menu .active .main_nav_menu_item svg * {
  transition: all 0.4s ease;
  color: #f7a34d;
  fill: #f7a34d;
}
.nav_profile {
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.nav_auth_btns {
  display: flex;
  padding: 11px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #69dd89;
  cursor: pointer;
  outline: none;
  border: none;
  font-family: "Rubik", sans-serif;
  color: #191d3e;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
}

.logged_user_profile {
  display: flex;
  align-items: center;
}
.nav_topup {
  display: flex;
  padding: 6px 6px 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 8px;
  background: linear-gradient(91deg, #223251 -0.17%, #275560 131.37%);
}

.nav_topup button {
  border-radius: 6px;
  background: #69dd89;
  width: 86px;
  height: 30px;
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: 10px;
  color: #223251;
  font-family: "Rubik", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 127.273% */
}

.nav_topup p {
  color: var(--Positive, #69dd89);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}
.nav_logged_user {
  width: 42px;
  height: 42px;
  border-radius: 8px;
  border: 2px solid #233452;
  cursor: pointer;
  overflow: hidden;
}

.nav_logged_user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1220px) {
  .main_nav_bar {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 820px) {
  .main_nav_bar .main_logo,
  .main_nav_bar .nav_profile {
    display: none;
  }
  .main_nav_bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 520px) {
  .main_nav_menu_item p {
    font-size: 14px;
  }
}