.page_title h1 {
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 123.81% */
  text-transform: uppercase;
  margin-top: 18px;
  margin-bottom: 20px;
}

.topup_filters {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.topup_filter_item {
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #242c53;
  transition: all 0.4s ease;
}
.topup_filter_item p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}

.topup_filter_item:hover {
  background: #1f2542fa;
  transition: all 0.4s ease;
}
.active_topup_filter {
  background: #1f2542fa;
  transition: all 0.4s ease;
}
.topup_content {
  display: flex;
  align-items: start;
}
.topup_cards_list_wrapper {
  width: 60%;
}
.topup_cards_list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

.topup_cards_list_item {
  display: flex;
  width: 134px;
  height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #252d55;
  cursor: pointer;
}

.topup_alert p {
  color: #7f8bad;
  margin-top: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.selected_topup_type {
  border: 2px solid rgba(105, 221, 137, 0.6);
  background: linear-gradient(
      178deg,
      rgba(105, 221, 137, 0) 2.01%,
      #69dd89 218.1%
    ),
    #202446;
}

.topuping_content {
  width: 40%;
  margin-left: 20px;
  margin-bottom: 120px;
}

.bonus_promocode_topup {
  padding: 24px 22px;
  border-radius: 15px;
  background: #252d55;
  position: relative;
}

.topup_bonus {
  position: relative;
  display: flex;
  width: 100%;
  height: 68px;
  padding: 12px 0px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: 1px solid rgba(57, 114, 100, 1);
  border-radius: 10px;
}

.promo_status_message  {
  font-size: 17px;
  font-weight: 500;
}

.bonus_line {
  color: rgba(105, 221, 137, 1);
}

.topup_bonus::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: url(../../assets/images/bgbonus.png) 100% 0 no-repeat, linear-gradient(90deg, #254755 11.61%, #244A56 100%);
  background-blend-mode: multiply;
  background-size: 100%;
  opacity: .5;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: -1;
}

.topup_bonus_wrapper{
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.topup_bonus_png {
  position: absolute;
  right: 0;
}
.topup_block {
  margin-top: 24px;
  padding: 22px;
  border-radius: 15px;
  background: #12152e;
}

.topup_block_title p {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  margin-bottom: 20px;
}

.topup_amount {
  width: 100%;
  padding: 12px 16px;
  border-radius: 10px;
  background: #202446;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.topup_amount input {
  margin-left: 12px;

  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  background: transparent;
  font-family: "Rubik", sans-serif;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.price_with_bonuses p {
  color: #7f8bad;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}
.price_with_bonuses span {
  color: #fbae21;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}

.topup_btn {
  width: 100%;
  margin-top: 32px;
  border-radius: 8px;
  background: #f7a34d;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #191d3e;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  border: none;
  outline: none;
  cursor: pointer;
}

.topup_btn svg {
  margin-left: 10px;
}
.topup_bonus_input{
  border: none;
  outline: none;
  background: #202446;
  font-family: "Rubik", sans-serif;
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 16px 12px 16px;
  border-radius: 10px;
  width: 100%;
  margin-right: 10px;

}
@media only screen and (max-width: 1200px) {
  .topup_content {
    flex-direction: column;
    gap: 50px;
  }
  .topuping_content {
    margin: 0;
    width: auto;
    max-width: 500px;
    padding-bottom: 30px;
  }
  .topup_cards_list_wrapper {
    width: auto;
    max-width: 720px;
  }
}
@media only screen and (max-width: 740px) {
  .topup_cards_list_wrapper {
    width: 100%;
    max-width: 720px;
  }
  .topup_cards_list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .topup_cards_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  .topuping_content {
    width: 100%;
  }
  .topup_bonus {
    padding: 0;
  }
  .topup_bonus img:first-child {
    width: 100%;
  }
  .bonus_promocode_topup {
    padding: 10px;
  }
}

.activate_promocode{
  display: flex;
  align-items: center;
}

.activate-promo_button{
  border: none;
  outline: none;
  background: #218838;
  font-family: "Rubik", sans-serif;
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 11px 33px 11px 33px;
  border-radius: 10px;
  margin-left: auto;
  transition: .3s;
}

.activate-promo_button:hover {
  background: #27a142;
}

@media only screen and (max-width: 500px) {
  .topup_filters {
    flex-wrap: wrap;
    gap: 5px;
  }
  .topup_cards_list_item {
    width: 100%;
  }
}


@media only screen and (max-width: 400px) {
  .topup_filter_item {
    margin: 0;
    padding: 8px;
  }
  .topup_cards_list_item {
    height: 60px;
  }
  .page_title h1 {
    padding: 0;
    margin: 0;
    padding-bottom: 40px;
  }
}