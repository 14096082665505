.spin_result {
  position: fixed;
  background: #000000c2;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 111;
  display: flex;
}
.spin_result_wrapper {
  margin: 0 auto;
  margin-top: 200px;
}
.spin_result_item_bg {
  background: #0333ab;
  border-radius: 50%;
}
.spin_result_item_image,
.spin_result_wrapper {
  position: relative;
}
.spin_result_item_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.spin_result_item_image {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spin_result_item-image {
  position: relative;
  width: 180px;
}

.spin_result_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spin_result_item_description p {
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.spin_result_item_cost p {
  text-align: center;
  color: #ddff00;
  margin-top: 5px;
}

.spin_result_item_id h2 {
  text-align: center;
  font-size: 30px;
  margin-top: 10px;
  color: #26ff00;
}
.retry_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.retry_btn button {
  margin-top: 30px;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  border-radius: 8px;
  background: #242c54;
  font-family: "Rubik", sans-serif;
  color: #fff;
}

.spin_result_item_description {
  position: relative;
}

.winned_block_actions_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
  margin-top: 30px;
  /*margin-left: -55px;*/
}
.winned_block_actions button {
  padding: 16px 32px;
  border: none;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #191d3e;
  text-transform: uppercase;
  border-radius: 8px;
  line-height: 20px; /* 125% */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.wrapper_multiple_sell_prize_btn button {
  padding: 6px 13px;
  border: none;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #191d3e;
  text-transform: capitalize;;
  border-radius: 8px;
  line-height: 20px; /* 125% */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.restart_roulette_btn button {
  background: var(--Positive, #69dd89);
}
.sell_prize_btn button {
  background: #f7a34d;
}
.case_opening_process_btn img {
  width: 25px;
}
.case_opening_item_img img{
  width: 94px;
  height: 94px;
}
.case_opening_items_wrapper_multiple{
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  height: 260px;
  overflow-y: clip;
  position: relative;
  border-radius: 12px;
  width: fit-content;
}
.case_opening_item.multiple{
  width: 183px;
  height: 260px;
  padding: 0px;
  justify-content: center;
}
.case_opening_left_gr_multiple {
  left: 0;
  top: 0;
  position: absolute;
}
.case_opening_right_gr_multiple {
  right: -1px;
  top: 0;
  position: absolute;
}
.wrapper_multiple_sell_prize_btn{
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
@media only screen and (max-width: 1000px) {
  .case_opening_item.multiple{
    width: 150px;
    height: 200px;
  }
  .case_opening_items_wrapper_multiple{
    height: 200px;
  }
  .case_opening_left_gr_multiple {
    left: -14px;
    height: 200px;
  }
  .case_opening_right_gr_multiple {
    height: 200px;
    right: -16px;
  }
  .case_opening_right_marker{
    width: 24px;
    height: 25px;
  }
  .case_opening_left_marker{
    width: 24px;
    height: 25px;
  }
}
@media only screen and (max-width: 800px) {
  .case_opening_item.multiple{
    width: 130px;
    height: 170px;
  }
  .case_opening_items_wrapper_multiple{
    height: 170px;
  }
  .case_opening_left_gr_multiple {
    left: -21px;
    height: 170px;
  }
  .case_opening_right_gr_multiple {
    height: 170px;
    right: -21px;
  }
  .case_opening_right_marker{
    width: 22px;
    height: 23px;
  }
  .case_opening_left_marker{
    width: 22px;
    height: 23px;
  }
}
@media only screen and (max-width: 720px) {
  .case_opening_item.multiple{
    width: 100px;
    height: 140px;
  }
  .case_opening_items_wrapper_multiple{
    height: 140px;
  }
  .case_opening_left_gr_multiple {
    left: -27px;
    height: 140px;
  }
  .case_opening_right_gr_multiple {
    height: 140px;
    right: -27px;
  }
  .case_opening_right_marker{
    width: 20px;
    height: 21px;
  }
  .case_opening_left_marker{
    width: 20px;
    height: 21px;
  }
  .case_opening_item.multiple .case_opening_item_description{
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .case_opening_item.multiple {
    width: 70px;
    height: 85px;
    min-width: 70px;
  }
  
  .case_opening_items_wrapper_multiple {
    height: 105px;
    overflow: hidden;
  }
  
  .case_opening_item.multiple .case_opening_item_img img {
    width: 70px;
    height: 70px;
  }
  
  .case_opening_left_gr_multiple,
  .case_opening_right_gr_multiple {
    height: 105px;
    width: 40px;
  }
  
  .case_opening_left_marker,
  .case_opening_right_marker {
    width: 14px;
    height: 15px;
  }
  
  .case_opening_multiple_items {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .case_opening_multiple_items::-webkit-scrollbar {
    display: none;
  }

  .wrapper_multiple_sell_prize_btn {
    display: flex;
    
    gap: 10px;
    justify-content: center;
   
    max-width: 300px;
  }

  .sell_prize_btn {
    width: 100%;
    max-width: 300px;
  }

  .sell_prize_btn button {
    width: 100%;
    font-size: 9px;
    
    
  }

  .winned_block_actions {
    padding: 10px;
  }

  .winned_block_actions_btns {
    flex-direction: column;
    gap: 10px;
  }

  .restart_roulette_btn button,
  .sell_prize_btn button {
    min-height: 44px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  .case_opening_item.multiple {
    width: 60px;
    height: 75px;
    min-width: 60px;
  }
  
  .case_opening_item.multiple .case_opening_item_img img {
    width: 60px;
    height: 60px;
  }
  
  .case_opening_items_wrapper_multiple {
    height: 95px;
  }

  .sell_prize_btn button {
    
    font-size: 10px;
  }

  .restart_roulette_btn button svg,
  .sell_prize_btn button svg {
    width: 18px;
    height: 18px;
  }
}